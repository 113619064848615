import '../../assets/css/signup.css'
import { useSelector, useDispatch } from "react-redux";
import {
    setToken,
    setLoading,
    setUserDetails,
} from "../../redux/user/user.action";
import { validateAll } from "indicative/validator";
import { Messages } from "../../common/msg"
import React, { useEffect, useState } from "react";
import ApiCall from "../../Api/index"
import ToastAlert from "../../common/ToastAlert";
import { useNavigate } from "react-router-dom";
import Setting from "./Setting";
import AddUserModal from "./AddUserModal";
import { useParams } from 'react-router';
import { setLogout } from "../../redux/user/user.action";
import AWS from 'aws-sdk'


function Index() {

    const { id } = useParams();
    const [buildingManager, setBuildingManagers] = useState([]);
    const [floorRepresentative, setFloorRepresentative] = useState([]);
    const [filterManager, setFilterManagers] = useState([]);
    const [filterFloor, setFilterFloors] = useState([]);
    const [searchManager, setSearchManager] = useState('');
    const [searchFloor, setSearchFloor] = useState('');
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user.userToken);
    const [buildingName, setBuildingName] = useState('');
    const [buildingNumber, setBuildingNumber] = useState('');
    const [buildingAddress, setBuildingAddress] = useState('');
    const [buildingImage, setBuildingImage] = useState('');
    const [buildingEmail, setBuildingEmail] = useState('');
    const [buildingCity, setBuildingCity] = useState('');
    const [buildingState, setBuildingState] = useState('');
    const [buildingStreetNumber, setBuildingStreetNumber] = useState('');
    const [buildingStreetName, setBuildingStreetName] = useState('');
    const [buildingZip, setBuildingZip] = useState('');
    const [buildingPhone, setBuildingPhone] = useState('');
    const [editUserID, setEditUserID] = useState('')

    const [searchVal, setSearchVal] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [allUserList, setAllUserList] = useState([])
    const [allUserListWithoutFilter, setAllUserListWithoutFilter] = useState(allUserList);
    const [isUserSaved, setIsUserSaved] = useState(false);
    const [userType, setUserType] = useState('')
    const [userModalTitle, setUserModalTitle] = useState('');



    const [formData, setFormData] = useState({
        email: "",
        firstName: "",
        password: "",
        image: "",
        error: {},
        input_type : true
    });

    const { email, firstName, password, user_id } = formData;

    const [formBuildingData, setFormBuildingData] = useState({
        building_name: buildingName,
        building_email: buildingEmail,
        building_streetNumber: buildingStreetNumber,
        building_streetName: buildingStreetName,
        building_city: buildingCity,
        building_state: buildingState,
        building_zip: buildingZip,
        building_phone: buildingPhone,
        error: {}
    });

    useEffect(() => {
        (async () => {
            dispatch(setLoading(true))
            const buildingList = await ApiCall('v1/manage-building-web', 'get', null, token);
            const siteManagers = await ApiCall('v1/manage-user-web/1/' + id + '/1', 'get', null, token);
            const floorList = await ApiCall('v1/manage-user-web/1/' + id + '/2', 'get', null, token);
            setBuildingManagers(siteManagers?.data?.RESULT?.data)
            setFilterManagers(siteManagers?.data?.RESULT?.data)

            setFloorRepresentative(floorList?.data?.RESULT?.data)
            setFilterFloors(floorList?.data?.RESULT?.data)
            if (buildingList.data) {
                dispatch(setLoading(false))
                const name = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.name
                const address1 = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.address1
                const address2 = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.address2
                const image = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.building_image
                const email = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.email
                const streetNumber = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.street_number
                const streetname = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.street_name
                const city = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.city
                const state = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.state
                const zip = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.zip
                const phone = buildingList?.data?.RESULT?.find((e) => Number(e.id) == Number(id))?.phone

                setBuildingName(name)
                setBuildingAddress(address1 + ' ' + address2)
                setBuildingImage(image)
                setBuildingEmail(email)
                setBuildingStreetNumber(streetNumber)
                setBuildingStreetName(streetname)
                setBuildingCity(city)
                setBuildingState(state)
                setBuildingZip(zip)
                setBuildingPhone(phone)


                setFormBuildingData({ building_name: name, building_email: email, building_streetNumber: streetNumber, building_city: city, building_state: state, building_zip: zip, building_streetName: streetname, building_phone: phone })
            } else {
                dispatch(setLoading(false))
                if (buildingList?.error?.response?.status === 401) {
                    dispatch(setLogout());
                } else if (buildingList.error == 'Unauthorized') {
                    dispatch(setLogout())
                }
                setBuildingManagers([])
                setFloorRepresentative([])
                setFilterManagers([])
                setFilterFloors([])
            }
        })()
    }, [isUserSaved])

    const  checkImage = async (url)  => {
        if(url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
            return true 
        } else {
            return false
        }
    }
      

    const onClickAddUser = async (id, editID, name, email, image, mTitle) => {
        setUserType(id)
        setEditUserID(editID)
        setModalShow(true)
        setUserModalTitle(mTitle)
        setSelectedFile('')
        setImagePreview('')
        if(! await checkImage(image)) {
            image  = ''
        }
        if (editID != undefined && editID != '') {
            setFormData({
                ...formData,
                email: email,
                firstName: name,
                image: image
            })
        }
    }


    const filterProprtyManagers = (e) => {
        setSearchManager(e.target.value);
        const buildingManagers = buildingManager?.filter(entry => Object.values(entry).some(val => val?.toString()?.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())))
        if (buildingManagers?.length > 0) {
            setFilterManagers(buildingManagers)
        } else {
            setFilterManagers([])
        }
    }

    const filterFloorRepresentative = (e) => {
        setSearchFloor(e.target.value);
        const floorList = floorRepresentative?.filter(entry => Object.values(entry).some(val => val?.toString()?.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())))
        if (floorList?.length > 0) {
            setFilterFloors(floorList)
        } else {
            setFilterFloors([])
        }
    }


    const handleForm = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const onHide = () => {
        setModalShow(false)
        setFormData({
            ...formData,
            email: "",
            firstName: "",
            password: "",
            image: "",
            error: {},
            setErrorMessage: {}
        })
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [latestFileUploadName, setLatestFileUploadName] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [imagePreview, setImagePreview] = useState(null);


    const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
    const REGION = process.env.REACT_APP_REGION;
    const MAX_FILE_SIZE_BYTES = 2 * 1024 * 1024;


    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    })


    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        console.log('file', file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
        console.log('file name', e.target.files[0])
        setSelectedFile(e.target.files[0]);
        console.log('setSelectedFile', setSelectedFile);
    }


    const submitForm = async (file) => {
        let rules = {}
        rules = {
            firstName: "required",
            ...(userType === 1 && { email: "required|email" }),
            ...(userType === 2 && { email: "required|regex:^[^@]+$" }),
            //password: "required|min:8",
        };

        const message = {
            "firstName.required": "First Name " + Messages.IS_REQUIRED,
            ...(userType === 1 && { "email.required": "Email " + Messages.IS_REQUIRED, "email.email": "Email " + Messages.IS_INVALID, }),
            ...(userType === 2 && { "email.required": "Username " + Messages.IS_REQUIRED, "email.email": "Username " + Messages.IS_INVALID,"email": "Username " + Messages.IS_INVALID,"email.regex": "Username " + Messages.IS_INVALID }),
            "password.required": "Password " + Messages.IS_REQUIRED,
            "password.min": "Password min 8 character " + Messages.IS_REQUIRED,
        };

        let newFileName = selectedFile?.name;
        let fileExtension = newFileName?.substr(newFileName.lastIndexOf('.') + 1);

        setErrorMessage('');
        if (newFileName != null && newFileName != undefined) {
            if (!['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                console.log('Select a valid image file (jpg, jpeg, or png).');
                setErrorMessage("Select a valid image file (jpg, jpeg, or png).");
                return false;
            }
            if (file?.size > MAX_FILE_SIZE_BYTES) {
                setErrorMessage("File size exceeds the maximum limit of 2MB .");
                console.log('File size exceeds the maximum limit of 2MB.');
                return false;
            }
        }

        console.log("submit", newFileName)


        let uniqueFileName = ''
        if (fileExtension != null) {
            uniqueFileName = makeid(10) + '.' + fileExtension;
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET + '/' + process.env.REACT_APP_USER_IMAGES_NAME,
                Key: uniqueFileName
            };

            myBucket.putObject(params)
                .on('httpUploadProgress', (evt) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100))
                    setLatestFileUploadName(uniqueFileName)
                })
                .send((err) => {
                    if (err) console.log("file error", err)
                })
        }

        validateAll(formData, rules, message)
            .then(async () => {
                setFormData({
                    ...formData,
                    error: {},
                });
                dispatch(setLoading(true))
                let payload = {}
                let save
                if (editUserID != undefined && editUserID != '') {

                    payload = {
                        name: firstName
                    }
                    if (password != '') {
                        payload.password = password
                        payload.send_new_password = 1
                    }
                    if (uniqueFileName == '') {
                        payload.image = formData.image;
                    } else {
                        payload.image = process.env.REACT_APP_IMAGE_URL + uniqueFileName
                    }
                    if (userType == '1') {
                        payload.email = email;
                    } else {
                        //payload.username = email;
                    }
                    save = await ApiCall('v1/manage-user/' + editUserID, 'put', payload, token);
                } else {
                    if (userType == '1') {
                        payload = {
                            user_type: userType,
                            name: firstName,
                            email: email,
                            password: password,
                            image: process.env.REACT_APP_IMAGE_URL + uniqueFileName,
                            building_id: id
                        }
                    } else {
                        payload = {
                            user_type: userType,
                            name: firstName,
                            username: email,
                            password: password,
                            image: process.env.REACT_APP_IMAGE_URL + uniqueFileName,
                            building_id: id
                        }
                    }
                    save = await ApiCall('v1/manage-user', 'post', payload, token);
                }
                console.log('save', save);
                if (save.data) {
                    setIsUserSaved(!isUserSaved)
                    onHide()
                    setModalShow(false)
                    ToastAlert({ title: "", msg: save?.data?.MESSAGE, msgType: "success" })
                    setLatestFileUploadName('');
                    setSelectedFile('')
                    dispatch(setLoading(false))
                } else if (save?.error?.response?.status === 401) {
                    dispatch(setLogout());
                } else {
                    ToastAlert({ title: "", msg: save?.error?.response?.data?.message || save?.error?.response?.data?.MESSAGE || Messages.SOMETHING_WENT_WRONG, msgType: "error" })
                    dispatch(setLoading(false))
                }
            })
            .catch((errors) => {
                const formaerrror = {};
                if (errors.length) {
                    errors.forEach(function (value) {
                        formaerrror[value.field] = value.message;
                    });
                } else {
                    ToastAlert({ title: "", msg: Messages.SOMETHING_WENT_WRONG, msgType: "error" })
                }
                console.log(formaerrror);

                setFormData({
                    ...formData,
                    error: formaerrror,
                });
            });
        setSearchVal('')
        setFormData({
            ...formData,
            email: "",
        });
        setAllUserList(allUserListWithoutFilter)

    };

    const showPassword = async () => {
        setFormData({
            ...formData,
            input_type: !formData.input_type,
        });
    }


    return (
        <>
            <Setting buildingName={buildingName} buildingAddress={buildingAddress} buildingImage={buildingImage} buildingEmail={buildingEmail} buildingManager={filterManager} filterProprtyManagers={filterProprtyManagers} search={searchManager} onClickAddUser={onClickAddUser} formBuildingData={formBuildingData} setFormBuildingData={setFormBuildingData} id={id} buildingCity={buildingCity} buildingState={buildingState} buildingStreetNumber={buildingStreetNumber} buildingZip={buildingZip} buildingStreetName={buildingStreetName} buildingPhone={buildingPhone} setBuildingImage={setBuildingImage} setBuildingName={setBuildingName} setBuildingEmail={setBuildingEmail} setBuildingStreetNumber={setBuildingStreetNumber} setBuildingStreetName={setBuildingStreetName} setBuildingCity={setBuildingCity} setBuildingState={setBuildingState} setBuildingZip={setBuildingZip} setBuildingPhone={setBuildingPhone} isUserSaved={isUserSaved} setIsUserSaved={setIsUserSaved} filterFloorRepresentative={filterFloorRepresentative} floorRepresentative={filterFloor} searchFloor={searchFloor} />
            <AddUserModal show={modalShow} formData={formData} handleForm={handleForm} handleFileInput={handleFileInput} submitForm={submitForm} onHide={() => onHide()} searchVal={searchVal} selectedFile={selectedFile} errorMessage={errorMessage} editUserID={editUserID} userModalTitle={userModalTitle} imagePreview={imagePreview} userType={userType} showPassword={showPassword} />
        </>
    )

}
export default Index